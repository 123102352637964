import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Card from "components/card";
import Socials from "components/socials";
import Question from "components/questionLoadable";
import FlatButton from "components/flatbutton";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import { Link, graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: ""}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/choose-pc2.png"}) { ...eyecatchImg },
    notedesktop_s: file(relativePath: { eq: "pc/note-desktop2_s.png"}) { ...smallImg },
    cnotedesktop: file(relativePath: { eq: "cover/pcselection/note-desktop.jpg"}) { ...eyecatchImg },
    note_hq: file(relativePath: { eq: "pc/note_hq.png"}) { ...normalImgWithHQ },
    convertible_s: file(relativePath: { eq: "pc/convertible_s.png"}) { ...smallImg },
    desktop_hq: file(relativePath: { eq: "pc/desktop_hq.png"}) { ...normalImgWithHQ },
    desktoppc_s: file(relativePath: { eq: "pc/desktop-pc_s.png"}) { ...smallImg },
    cpu_s: file(relativePath: { eq: "pc/cpu_s.png"}) { ...smallImg },
    ccpu: file(relativePath: { eq: "cover/pcparts/cpu.jpg"}) { ...eyecatchImg },
    ccpuhikaku: file(relativePath: { eq: "cover/cpu/cpu-hikaku.png"}) { ...eyecatchImg },
    memory_s: file(relativePath: { eq: "pc/memory_s.png"}) { ...smallImg },
    memory: file(relativePath: { eq: "cover/pcparts/memory.png"}) { ...eyecatchImg },
    ssd_s: file(relativePath: { eq: "pc/ssd_s.png"}) { ...smallImg },
    ssd_youryou: file(relativePath: { eq: "storage/ssd_youryou.png"}) { ...normalImg },
    cssd_size: file(relativePath: { eq: "cover/pcparts/ssd_size.PNG"}) { ...eyecatchImg },
    graphicsboard_s: file(relativePath: { eq: "pc/graphics-board_s.png"}) { ...smallImg },
    cgrabo: file(relativePath: { eq: "cover/pcparts/grabo.png"}) { ...eyecatchImg },
    cgrabohikaku: file(relativePath: { eq: "cover/gpu/grabo-hikaku.png"}) { ...eyecatchImg },
    windowsos_s: file(relativePath: { eq: "pc/windows-os.png"}) { ...smallImg },
    microsoftoffice_s: file(relativePath: { eq: "pc/microsoft-office.png"}) { ...smallImg },
    coffice: file(relativePath: { eq: "cover/pcparts/office.PNG"}) { ...eyecatchImg },
    monitor_s: file(relativePath: { eq: "pc/monitor_s.png"}) { ...smallImg },
    fullHD_s: file(relativePath: { eq: "monitor/fullHD.png"}) { ...smallImg },
    cmonitor: file(relativePath: { eq: "cover/pcparts/monitor.png"}) { ...eyecatchImg },
    cvirus: file(relativePath: { eq: "cover/pcparts/virus.png"}) { ...eyecatchImg },
    keyboardmouse_s: file(relativePath: { eq: "pc/keyboard-mouse_s.png"}) { ...smallImg },
    bddvddrive_s: file(relativePath: { eq: "pc/bd-dvd-drive_s.png"}) { ...smallImg },
    cdrive: file(relativePath: { eq: "cover/pcparts/drive.jpg"}) { ...eyecatchImg },
    wirelesslan_s: file(relativePath: { eq: "pc/wireless-lan_s.png"}) { ...smallImg },
    noteconnect: file(relativePath: { eq: "tanshi/note-connect.png"}) { ...normalImg },
    cusbtypec: file(relativePath: { eq: "cover/usbtypec.png"}) { ...eyecatchImg },
    cbeginner: file(relativePath: { eq: "cover/pcselection/beginner.png"}) { ...eyecatchImg },
    cdaigakusei: file(relativePath: { eq: "cover/pcselection/daigakusei.png"}) { ...eyecatchImg },
    cbusiness: file(relativePath: { eq: "cover/pcselection/business.png"}) { ...eyecatchImg },
    cgamingpc: file(relativePath: { eq: "cover/pcselection/gamingpc.png"}) { ...eyecatchImg },
    ceditvideo: file(relativePath: { eq: "cover/pcselection/editvideo.png"}) { ...eyecatchImg },
    pcmaker: file(relativePath: { eq: "pc/pc-maker.png"}) { ...normalImg },
    cpcmaker: file(relativePath: { eq: "cover/rank/pcmaker.png"}) { ...eyecatchImg },
    cwhere: file(relativePath: { eq: "cover/others/where.png"}) { ...eyecatchImg },
    cusedpc: file(relativePath: { eq: "cover/others/usedpc.png"}) { ...eyecatchImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`パソコン選び方ガイド`}</h1>
    <time itemProp="datePublished" dateTime="Sat Oct 12 2024 00:56:48 GMT+0900 (日本標準時)">更新日:2024年10月12日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="パソコン選び方ガイド" mdxType="Image" />
    <div style={{
      textAlign: 'center'
    }}>
      <FlatButton onClick={() => document.querySelector('#recommend').scrollIntoView()} className="top-margin" mdxType="FlatButton">パソコン診断をすぐはじめる</FlatButton>
    </div>
    <p>{`パソコンを購入するためには大きく分けて`}<strong parentName="p">{`ノートパソコンやデスクトップなどの`}<em parentName="strong">{`種類`}</em></strong>{`、`}<strong parentName="p">{`CPUやメモリ、SSDなどの`}<em parentName="strong">{`スペック`}</em></strong>{`、`}<strong parentName="p">{`NECやデルなどの`}<em parentName="strong">{`メーカー`}</em></strong>{`の３つを決めていく必要がある。`}</p>
    <p>{`本サイトでは元ヨドバシの販売員でソフトウェアエンジニアの管理人が、これら項目別に基礎知識を含めパソコンの選び方を解説する他、より簡単におすすめのパソコンを知りたい人へ向け、`}<strong parentName="p">{`アンケートによる`}<b>{`パソコン診断`}</b></strong>{`も行っている。`}</p>
    <p>{`別ページにてCPUやGPUの性能比較表やパーツ別パソコンの選び方も用意しているので、より詳細な検討を行う場合にはメニューからたどって欲しい。`}</p>
    <p>{`本サイトを通して自分に合ったパソコンを見つけ、納得のいくパソコンを購入しよう。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <div id="recommend" />
    <h2 {...{
      "id": "自分におすすめのパソコンメーカー、スペック、種類等を診断",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E8%87%AA%E5%88%86%E3%81%AB%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%AE%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%83%A1%E3%83%BC%E3%82%AB%E3%83%BC%E3%80%81%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E3%80%81%E7%A8%AE%E9%A1%9E%E7%AD%89%E3%82%92%E8%A8%BA%E6%96%AD",
        "aria-label": "自分におすすめのパソコンメーカー、スペック、種類等を診断 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`自分におすすめのパソコン(メーカー、スペック、種類等)を診断`}</h2>
    <p>{`さっそく診断をしてみよう！パソコン選びのポイントも含めて教えてくれます。初心者でもこの結果に従ってパソコンを購入すれば失敗しないはず。`}</p>
    <Question {...props} mdxType="Question" />
    <br />
    <h2 {...{
      "id": "ノート、デスクトップ？パソコンの種類を絞り込み",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%80%81%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%EF%BC%9F%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E7%A8%AE%E9%A1%9E%E3%82%92%E7%B5%9E%E3%82%8A%E8%BE%BC%E3%81%BF",
        "aria-label": "ノート、デスクトップ？パソコンの種類を絞り込み permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノート、デスクトップ？パソコンの種類を絞り込み`}</h2>
    <p>{`ノートとデスクトップの選択は、場所を問わず使いたいならばノートパソコン一択であるが、迷っているならばデスクトップのメリットも把握していくべきである。`}</p>
    <p>{`また、ノートパソコンならば`}<b>{`スタンダードノート`}</b>{`、`}<b>{`モバイルノート`}</b>{`、`}<b>{`2 in 1ノート`}</b>{`、`}<b>{`ゲーミングノート`}</b>{`、デスクトップならば`}<b>{`タワー型`}</b>{`、`}<b>{`スリム型`}</b>{`、`}<b>{`一体型`}</b>{`と更に細かく製品を分けることができる。`}</p>
    <p>{`それぞれ特徴を理解した上で、どれが自分に適しているのかを把握していこう。`}</p>
    <h3 {...{
      "id": "ノートPCかデスクトップPCか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88PC%E3%81%8B%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97PC%E3%81%8B%EF%BC%9F",
        "aria-label": "ノートPCかデスクトップPCか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートPCかデスクトップPCか？`}</h3>
    <Image {...props} name="notedesktop_s" alt="ノートかデスクトップか" mdxType="Image" />
    <p>{`外出先で使うならばノートパソコン一択であるが、`}<strong parentName="p">{`定位置で使えるならばデスクトップのメリットにも目を向けたい`}</strong>{`。ここで３つ利点を挙げてみる。`}</p>
    <p><em parentName="p">{`１つ目は性能が高いこと`}</em>{`。筺体が大きいため冷却性能が高く、バッテリーの心配もないため高性能なCPUを安定して動作させることができる。`}</p>
    <p><em parentName="p">{`２つ目は拡張の自由度が高いこと`}</em>{`。パソコンが重くなってきたと感じたらメモリを、ゲームが行いたいと思えばグラフィックボードを増設といったことが簡単にでき、`}<strong parentName="p">{`パソコンの購入時に全てを揃えておく必要がない`}</strong>{`。また端子の数も多いのでスピーカーやUSBメモリなどを差し替える必要がなくて楽。`}</p>
    <p><em parentName="p">{`３つ目は楽な態勢で作業ができること`}</em>{`。ディスプレイとキーボードが本体から分離されているため、それらを好きな場所に配置して使うことができる。ノートパソコンはキーボードの幅も狭く、どうしても体勢が窮屈になり疲れてしまう。`}<strong parentName="p">{`長時間作業にはデスクトップPCの方が向いている`}</strong>{`。`}</p>
    <p>{`家で作業を行うときはデスクトップで行い、出かけ先でノートを使うというスタイルも一般的である。`}</p>
    <Card {...props} title="ノートかデスクトップどちらを購入すべき？" description="それぞれのメリットを整理してケース別に考えてみよう。" image="cnotedesktop" path="/which/" mdxType="Card" />
    <h3 {...{
      "id": "ノートパソコンの選び方",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E9%81%B8%E3%81%B3%E6%96%B9",
        "aria-label": "ノートパソコンの選び方 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートパソコンの選び方`}</h3>
    <p>{`ノートパソコンは用途に応じて様々な大きさや特徴のものがあり、大別すると下図のように`}<em parentName="p">{`スタンダードノート`}</em>{`、`}<em parentName="p">{`モバイルノート`}</em>{`、`}<em parentName="p">{`2 in 1ノート`}</em>{`、`}<em parentName="p">{`ゲーミングノート`}</em>{`の４種類に分類できる。`}</p>
    <Image {...props} name="note_hq" alt="ノートパソコンの種類と特徴" mdxType="Image" />
    <p><em parentName="p">{`スタンダードノート`}</em>{`は15インチ~17インチ程度までのパソコンで、主に家の中で場所を選ばずに使いたい場合におすすめできる。`}</p>
    <p>{`画面サイズが大きいため作業が行いやすく、DVD/BDドライブ、十分な数のUSB端子など、パソコン作業に必要なインターフェースが一通り備わっている。`}</p>
    <p>{`またコストパフォーマンスも高く、`}<strong parentName="p">{`パソコン初心者にとっても最適な一台`}</strong>{`となる。`}</p>
    <p><em parentName="p">{`モバイルノート`}</em>{`は外出先で使用することを前提としたパソコンで、サイズが12.4 ～ 14.1インチ程度、重量が700g ~ 1.4kg程度のものがモバイルノートに当てはまる。`}<strong parentName="p">{`1kg以下だと体感的にも`}<em parentName="strong">{`「軽っ!」`}</em>{`と感じるレベル`}</strong>{`となる。`}</p>
    <p>{`軽量であることに加えロングバッテリーも重視しており、低消費電力のCPUを搭載することでバッテリー寿命は10時間を超えるタイプが多い。(ただし`}<strong parentName="p">{`バッテリー駆動時間は公称値の７割程度`}</strong>{`の場合が多いので余裕をもって見積もると良い。)`}</p>
    <p>{`パワフルなCPUは搭載できないためCPU性能には注意が必要で`}<a href="/cpu/">{`ベンチマーク`}</a>{`は必ずチェックしておきたい。また、画面の小ささを補うために`}<strong parentName="p">{`外付けのモニターで拡張する`}</strong>{`ことも一般的である。`}</p>
    <Image {...props} name="convertible_s" alt="コンバーチブルノート" mdxType="Image" />
    <p><em parentName="p">{`2 in 1ノート`}</em>{`はモバイルノートの一種ではあるが、タッチパネルに対応しており、タブレットとしても使えるものを言う。`}</p>
    <p><strong parentName="p">{`キーボードを取り外すことができる`}<em parentName="strong">{`着脱式`}</em>{`のものと図のように`}<em parentName="strong">{`コンバーチブル`}</em>{`と呼ばれる360度画面を回転できるものの２種類`}</strong>{`がある。`}</p>
    <p>{`2 in 1は自由な姿勢でパソコンを見られるため、YoutubeやNetflixなどの`}<strong parentName="p">{`動画をリラックスして快適に閲覧できる`}</strong>{`。また、タッチ操作やペンでのお絵描きも楽しい。`}</p>
    <p>{`着脱式はより快適に動画やお絵描きを楽しめるが、ノートパソコンとして使う時は安定性が悪いため仕事には向かないだろう。`}</p>
    <p><em parentName="p">{`ゲーミングノート`}</em>{`は本格的な3Dゲームを楽めるノートパソコンである。スペックが高いため非常に高価であり、3Dゲームの他動画編集作業などの重い作業も快適にできる。`}</p>
    <p>{`ただし、グラフィックボードを搭載している分重量があり、バッテリー消費も激しいため持ち運びは結構しんどい。`}</p>
    <p>{`最後にノートパソコン選びの注意点として、`}<strong parentName="p">{`日常的にパソコンを使用する人はCPU性能が高いモデルをおすすめ`}</strong>{`する。`}</p>
    <p>{`ノートパソコンはデスクトップに比べてCPU性能が低いため、動作がもっさりと感じやすい。長時間の作業では、CPUの速度が生産性の低下を招いてしまうので注意が必要である。後述のCPUの選び方をしっかりと理解して選択しよう。`}</p>
    <h3 {...{
      "id": "デスクトップパソコンの選び方",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E9%81%B8%E3%81%B3%E6%96%B9",
        "aria-label": "デスクトップパソコンの選び方 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デスクトップパソコンの選び方`}</h3>
    <p>{`デスクトップパソコンは大きく分けると、下図のように`}<em parentName="p">{`一体型`}</em>{`、`}<em parentName="p">{`スリム型`}</em>{`、`}<em parentName="p">{`タワー型(ミニタワー型含む)`}</em>{`の３種類がある。選ぶには拡張性とスペース、デザイン性の高さを天秤に掛けることになる。タワー型から説明する。`}</p>
    <Image {...props} name="desktop_hq" alt="デスクトップパソコンの種類と特徴" mdxType="Image" />
    <p><em parentName="p">{`タワー型`}</em>{`は横幅20cm程度の大型パソコンで、明確な基準はないものの大きさによりミニタワー・ミドルタワーなどさらに分かれる。`}</p>
    <p>{`拡張性が高く、パーツを簡単に付け足せるため、`}<strong parentName="p">{`パソコン購入後のニーズの変化に柔軟に対応できることがタワー型デスクトップパソコンの大きな魅力`}</strong>{`である。`}</p>
    <p>{`例えば、VRゲームが遊びたくなればメモリとグラフィックボードを、動画で容量が足りなくなればストレージを増設できる。基本的に本体のふたを開けて部品を挿し、再起動をかけるだけの簡単作業である。`}</p>
    <p><em parentName="p">{`スリム型`}</em>{`は横幅10cm程度の`}<strong parentName="p">{`省スペースと、高いコストパフォーマンス`}</strong>{`が魅力である。`}</p>
    <p>{`拡張性は高くなく、メモリーの増設程度ならば簡単であるが、グラフィックボードは小型のもの以外は難しい。`}</p>
    <p>{`しかし、`}<strong parentName="p">{`パワフルなCPUを搭載することができ、パソコン作業は快適にできる`}</strong>{`。このためビジネス用のパソコンとしても人気が高い。`}</p>
    <p><em parentName="p">{`一体型`}</em>{`は`}<strong parentName="p">{`更なる省スペース化を目指す場合や、インテリアを重視したい場合`}</strong>{`におすすめである。`}</p>
    <p>{`配線がすっきりしてデザイン的にも優れており、リビングへの設置や一人暮らしのワンルームでパソコンとテレビを兼ねたい場合などにも活躍する`}</p>
    <p>{`ただし、壊れたときに修理費が嵩み、拡張性も無い。また、一体型は放熱処理の難しさから`}<strong parentName="p">{`低消費電力のノート用CPUを使用しているため馬力が弱い`}</strong>{`。買い替え時もディスプレイだけ使い続けることは不可能である。`}</p>
    <p>{`省スペースやインテリアを重視するために、これら多くの不利益を受け入れる必要があることは念頭に入れておこう。`}</p>
    <h2 {...{
      "id": "パソコンの選び方を項目別にアドバイス",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E9%81%B8%E3%81%B3%E6%96%B9%E3%82%92%E9%A0%85%E7%9B%AE%E5%88%A5%E3%81%AB%E3%82%A2%E3%83%89%E3%83%90%E3%82%A4%E3%82%B9",
        "aria-label": "パソコンの選び方を項目別にアドバイス permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコンの選び方を項目別にアドバイス`}</h2>
    <p>{`パソコンはCPUやメモリ、ストレージなど様々な部品や、WindowsをはじめとするOSと呼ばれるソフトウェアや、様々なアプリケーションを組み合わせて一つの製品として販売されている。`}</p>
    <p>{`このため非常に複雑であることは事実だが、`}<strong parentName="p">{`一つ一つ用語とともに選び方のポイントを抑えることで、自分に最適なスペックや初期ソフトが見えてくる`}</strong>{`。しっかりと見極めよう。`}</p>
    <h3 {...{
      "id": "CPU",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#CPU",
        "aria-label": "CPU permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPU`}</h3>
    <Image {...props} name="cpu_s" alt="CPUの選び方" mdxType="Image" />
    <p>{`CPU(Central Processing Unit)は中央演算処理装置とも呼ばれ、パソコンの制御を担当する心臓部にあたる部品である。`}</p>
    <p><strong parentName="p">{`CPUの性能が高いとアプリがサクサクと動くが`}</strong>{`、低いとインターネットの閲覧だけでも動作がもっさりと感じられる。`}</p>
    <p>{`CPUのメーカーはインテルとAMDの２社であり、それぞれの次のようなラインナップとなる。`}</p>
    <AccentBox title="CPUの種類" type="dia" mdxType="AccentBox">
  <li><span className="bold">Core i7, Core i9</span>・・・Intelのハイエンドモデル</li>
  <li><span className="bold">Core i5</span>・・・Intelのミドルエンドモデル</li>
  <li><span className="bold">Core i3</span>・・・Intelのロー/ミドルエンドモデル</li>
  <li><span className="bold">Pentium, Celeron</span>・・・Intelのローエンドモデル</li>
  <li><span className="bold">Ryzen</span>・・・AMDのハイエンド/ミドルエンドモデル</li>
  <li><span className="bold">Athlon</span>・・・AMDのローエンドモデル</li>
    </AccentBox>
    <p>{`また`}<strong parentName="p">{`Intelの新世代CPUとして`}<em parentName="strong">{`Core Ultra`}</em>{`シリーズもCore iシリーズから代わる形で登場している`}</strong>{`が、数値の付け方自体は変わっておらず、ハイエンドモデルならばCore Ultra 7,9である。従来Core iシリーズであったが、新世代iが無い点にも注意したい。`}</p>
    <p>{`おおよその目安として`}<strong parentName="p">{`動画編集、ゲームを快適にするならばCore 5以上`}</strong>{`のスペックは必要。`}<strong parentName="p">{`Officeを使うならばCore 3以上`}</strong>{`、`}<strong parentName="p">{`メールとネットの閲覧が主で使用頻度が低いならばCeleron,Atom`}</strong>{`といった低速のCPUでも構わない。`}</p>
    <p>{`ただし、同じ例えばCore 5でもデスクトップ向けとノート向けで種類が分かれており、また製造された時期(=世代と呼ぶ)により型番が異なり、性能も大きく異なる。例えば次のようなものがある。`}</p>
    <AccentBox title="Core i5 CPUの型番" type="dia" mdxType="AccentBox">
  <li><span className="bold">Core i5 14600K</span>・・・第14世代、デスクトップのオーバークロック対応版</li>
  <li><span className="bold">Core i5 1360P</span>・・・第13世代、グラフィック強化版、動画編集に強くゲームもそこそこ</li>
  <li><span className="bold">Core i5 1240U</span>・・・第12世代、低消費電力版、ビジネス用途などに向いている</li>
  <li><span className="bold">Core i5 11500H</span>・・・第11世代、ゲーミングノート向け</li>
  <li><span className="bold">Core Ultra 5 125U</span>・・・新第１世代、第14世代より新しい。低消費電力版</li>
    </AccentBox>
    <p>{`オーバークロックとはメーカー側が放熱性などを考慮して意図的に抑えているCPU性能を意図的に引き上げる技術で、パソコンに詳しい層で活用されている。`}</p>
    <p><strong parentName="p">{`異なる型番の性能を比較する手段として、CPUの総合的な性能を把握して点数を出してくれるベンチマークというものがある`}</strong>{`。より詳細に検討したい場合はリンクのCPU性能比較表を参考にして欲しい。`}</p>
    <p>{`また、`}<strong parentName="p">{`ノートパソコン用のCPUは低消費電力でパフォーマンスが出にくい`}</strong>{`ため、Core 3と5で迷ったら5にするなど気持ちスペック高めが望ましい。`}</p>
    <Card {...props} title="CPUの選び方" description="CPUとは何か？IntelとAMDの２大メーカーのラインナップや特徴などを分かりやすい図とともに解説していきます。" image="ccpu" path="/cpu-select/" mdxType="Card" />
    <Card {...props} title="CPU性能比較表" description="400を超えるCPUから高機能フィルタにより世代間/ベンチマーク/TDP/コスパ等の絞りこみが行えます。" image="ccpuhikaku" path="/cpu/" mdxType="Card" />
    <h3 {...{
      "id": "メモリ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA",
        "aria-label": "メモリ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリ`}</h3>
    <Image {...props} name="memory_s" alt="メモリの選び方" mdxType="Image" />
    <p><strong parentName="p">{`大抵は8GB程度がおすすめ`}</strong>{`。
メモリはパソコンにおける作業スペースの役割を果たしている。
音楽を流しながらExcelを開き、インターネットをしつつ、とながら作業を行うと、スペースが足りなくなり動作が重くなってしまう。しかし8GBあればこの状況でも軽快に動作する。`}</p>
    <p>{`インターネットや動画視聴を中心とし、ながら作業があまりない場合は4GBでも問題がない。`}</p>
    <p>{`16GBが必要となるケースは決して多くないが、次のような場合がある。`}</p>
    <AccentBox title="16GBのメモリで嬉しいケース" mdxType="AccentBox">
  <li><span className="bold">最新の3DゲームやVRをする</span>・・・ゲーム中にWebサイトの閲覧等すると動作が不安定になる可能性がある。また、高度な3Dゲームの推奨メモリスペックは8GBを超える。</li>
  <li><span className="bold">プロ仕様の動画編集ソフトを使う</span>・・・4K動画の編集やAdobe AfterEffectsで映画のような映像をといった場合は最低が16GB。32GB欲しいケースも多い。</li>
  <li><span className="bold">Webブラウザのタブを大量に開く</span>・・30個とか常時開いている人だとメリットはあるかも。</li>
  <li><span className="bold">Androidなどのアプリを開発する</span>・・エミュレータでメモリを消費する、そこまでの必要性はないが積んでおいても良い。</li>
    </AccentBox>
    <p>{`DDR5-4800、PC5-38400など馴染みのない規格が表記されている事があるが、4800、38400の部分が実際のメモリー速度を表す。`}</p>
    <p><strong parentName="p">{`数値が大きいほど高速であるが、メモリの量と比べると重要度は落ちる`}</strong>{`ためそこまで気にする必要は無い。メモリの選び方で説明しているが、DDR5-4800とPC5-38400はほぼ同じ意味である。DDRの数値を8倍するとPC4の数値となる。`}</p>
    <p>{`他には同じ種類のメモリを２つ搭載して高速化する`}<em parentName="p">{`デュアルチャネル`}</em>{`など細かい技術もあるが、より深く知りたい人は下記を参照。`}</p>
    <Card {...props} title="メモリの選び方" description="メモリを8GB、16GBどちらにすべきかと悩んだら参考になります。" image="memory" path="/memory/" mdxType="Card" />
    <h3 {...{
      "id": "ストレージSSDHDD",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B9%E3%83%88%E3%83%AC%E3%83%BC%E3%82%B8SSDHDD",
        "aria-label": "ストレージSSDHDD permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ストレージ(SSD/HDD)`}</h3>
    <Image {...props} name="ssd_s" alt="SSD/HDDの選び方" mdxType="Image" />
    <p>{`ストレージは動画や音楽といったファイルを保存するために必要なパーツである。`}</p>
    <p><em parentName="p">{`SSD`}</em>{`(Solid State Drive)と`}<em parentName="p">{`HDD`}</em>{`(ハードディスクドライブ, Hard Disk Drive)の２種類がある。`}<strong parentName="p">{`SSDは高価で高速でかつ耐久性も高い、HDDは安価で大容量`}</strong>{`という特徴を持つ。`}</p>
    <p>{`容量の単位はGB(ギガバイト)である。スマートフォンのギガも同じ意味で使われている。イメージを持ってもらうため、100GBでできることは`}<strong parentName="p">{`音楽なら20000曲、写真でも20000枚、番組録画ならば12時間、4Kの高画質で撮影した動画なら2時間、本格的なゲームのインストールなら２本`}</strong>{`といったところ。`}</p>
    <p>{`HDDかSSD、あるいはその両方をパソコンに搭載することになるが、どの容量にすべきかはパソコンをカスタマイズして購入する場合に必ず聞かれる事になる。およその容量の目安は次の通り。`}</p>
    <AccentBox title="SSD/HDDの容量の目安" mdxType="AccentBox">
  <li><span className="bold">128GB</span>・・・インターネットと動画の閲覧がメインで文書作成程度しか作業を行わない場合</li>
  <li><span className="bold">256GB</span>・・・写真や音楽、動画を保存するものの数は多くななく、アプリは色々インストールして使いたい場合</li>
  <li><span className="bold">512GB</span>・・写真や動画をそこそこ保存する場合、ゲームをインストールする予定の場合</li>
  <li><span className="bold">1TB</span>・・動画や写真をガンガン保存する場合。動画の撮影・編集が趣味の場合。テレビを録画したい場合。容量を気にせず使いたい場合</li>
  <li><span className="bold">4TB以上</span>・・録画番組を高画質で取りためたい、4K動画をガンガン保存したい場合</li>
    </AccentBox>
    <p>{`SSDはパソコンの立ち上げ時間が半分になるなどメリットが大きいため、基本的に`}<strong parentName="p">{`SSDをメインとし、容量が不足したら動画などの大きいファイルをHDDへ移すという使い方が一般的`}</strong>{`である。`}</p>
    <p><strong parentName="p">{`外付けのハードディスクは安く買え、パソコン
購入後でも容量を簡単に増やすことができる`}</strong>{`ため、神経質に決める必要はない。`}</p>
    <p>{`ただ、メインで使うSSDの容量はよりシビアに見ていく必要がある。128GB、256GB、512GB、1TBで迷ったならば次の図を参考にしてほしい。`}</p>
    <Image {...props} name="ssd_youryou" alt="SSD容量の目安" mdxType="Image" />
    <p>{`より詳しくは下記の記事を参考に。`}</p>
    <Card {...props} title="【256GB,512GB,1TB】SSDの容量のおすすめは何か？" description="ストレージの容量を決めかねている場合に参考になる情報を網羅" image="cssd_size" path="/ssd/" mdxType="Card" />
    <h3 {...{
      "id": "グラフィックボード（GPU、グラボ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B0%E3%83%A9%E3%83%95%E3%82%A3%E3%83%83%E3%82%AF%E3%83%9C%E3%83%BC%E3%83%89%EF%BC%88GPU%E3%80%81%E3%82%B0%E3%83%A9%E3%83%9C",
        "aria-label": "グラフィックボード（GPU、グラボ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`グラフィックボード（GPU、グラボ)`}</h3>
    <Image {...props} name="graphicsboard_s" alt="グラフィックボード" mdxType="Image" />
    <p>{`グラフィックボードは3D映像の描画や並列計算処理に特化したパーツで、GPU(Graphic Processing Unit)と呼ばれる。CPUでは苦手な処理をGPUが担当するイメージである。`}</p>
    <p>{`パソコンに必ず必要なパーツではないが、`}<strong parentName="p">{`本格的な3Dゲームを楽しみたい場合は必須`}</strong>{`である。`}<strong parentName="p">{`グラフィックボード無しではまともに遊べないタイトルも多い`}</strong>{`。`}</p>
    <p>{`その他、動画像編集の速度アップ、3画面以上のディスプレイ環境の構築、仮想通貨のマイニングなどにも使える。グラフィックボードを搭載すべきか悩んだら下記の記事を参考にして欲しい。`}</p>
    <Card {...props} title="グラフィックボードは必要か？" description="グラフィックボードの必要性から用途別にどの価格帯のグラフィックボードが必要なのかを徹底解説。" image="cgrabo" path="/grabo/" mdxType="Card" />
    <p><strong parentName="p">{`VRや高解像度の3Dゲームを快適に楽しみたい場合は、コストパフォーマンスの面も考えると`}<em parentName="strong">{`GeForce RTX 4060`}</em>{`か`}<em parentName="strong">{`GeForce GTX 1660`}</em></strong>{`あたりが妥当な選択肢となる。特にRTXシリーズは`}<em parentName="p">{`レイトレーシング`}</em>{`という現実世界に近い光の描写を実現する技術に対応しており、美麗なグラフィックを楽しめる。`}</p>
    <p><em parentName="p">{`4K画質`}</em>{`で快適に遊びたい場合や`}<em parentName="p">{`144FPS`}</em>{`というより滑らかな映像を楽しみたい場合には`}<strong parentName="p">{`対応するモニタも必要`}</strong>{`となるため注意が必要である。どのグラフィックボードでどれぐらいの性能を出せるかはグラボ性能比較表を参照して欲しい。`}</p>
    <Card {...props} title="グラボ(GPU)性能比較表" description="グラフィックボードの比較を簡単に。ゲームに必要な性能をFullHD、QHD、4Kの参考フレームレート、3DMark、コスパ、TDP等で比較します。" image="cgrabohikaku" path="/gpu/" mdxType="Card" />
    <p>{`グラフィックボードは重く、価格も高いため、持ち運び用のノートパソコンを考えている場合や、費用を抑えたい場合は搭載をあきらめるざるを得ない可能性もあるだろう。`}</p>
    <h3 {...{
      "id": "OS-オペレーティングシステム",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#OS-%E3%82%AA%E3%83%9A%E3%83%AC%E3%83%BC%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0%E3%82%B7%E3%82%B9%E3%83%86%E3%83%A0",
        "aria-label": "OS オペレーティングシステム permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OS (オペレーティングシステム)`}</h3>
    <Image {...props} name="windowsos_s" alt="オペレーティングシステムの選び方" mdxType="Image" />
    <p>{`OS(Operating System、 オペレーティングシステム)とはアプリケーションを動作させるための基盤ソフトウェアのことであり、パソコンではマイクロソフトの`}<em parentName="p">{`Windows`}</em>{`、Appleの`}<em parentName="p">{`macOS`}</em>{`、Googleの`}<em parentName="p">{`Chrome OS`}</em>{`がある。 `}</p>
    <p>{`Chrome OSはネットショッピングとYoutube用途と割り切ればよい結論であり、OSが軽いため安価なCPUでもサクサク動作する。またmac OSはiphoneアプリの開発の他、特にデザイン用途での人気が高い。しかし`}<strong parentName="p">{`一般的にはシェア９割のWindowsが総合的な使い勝手に優れ、妥当な選択肢である`}</strong>{`。`}</p>
    <p>{`WindowsにはProとHomeの大きく２つのEditionがあり、Proはセキュリティ機能が優れている他、離れた場所にあるパソコンを遠隔で操作できる`}<em parentName="p">{`リモートデスクトップ`}</em>{`が使用できるというメリットがある。`}</p>
    <p>{`ただProは企業向けの使用が主なので、テレワークで会社のパソコンに繋げたいような場合は必須となるだろうが、`}<strong parentName="p">{`基本的にはWindowsのHome Editionを選んでおけば問題はない`}</strong>{`。`}</p>
    <h3 {...{
      "id": "オフィスソフトWordExcelPowerPoint",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AA%E3%83%95%E3%82%A3%E3%82%B9%E3%82%BD%E3%83%95%E3%83%88WordExcelPowerPoint",
        "aria-label": "オフィスソフトWordExcelPowerPoint permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`オフィスソフト(Word,Excel,PowerPoint)`}</h3>
    <Image {...props} name="microsoftoffice_s" alt="オフィスソフトの選び方" mdxType="Image" />
    <p>{`文書作成のWord、表計算のExcel、プレゼンテーション用のPowerPointが良く使われるアプリケーションである。`}</p>
    <p><strong parentName="p">{`Excel、Word、メール管理ソフトのOutlookがセットとなった`}<em parentName="strong">{`Windows Office Personal`}</em>{`と、PowerPointも付属した`}<em parentName="strong">{`Windows Office Home and Business`}</em></strong>{`がある。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>パッケージ名</th>
      <th>ソフトウェア</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Office Personal</td>
      <td>Word、Excel、Outlook</td>
    </tr>
    <tr>
      <td>Office Home &amp; Business</td>
      <td>Word、Excel、Outlook、PowerPoint</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`学生ならばレポート作成やゼミの発表で必要になるはずなので、持っていなければHome and Businessを付けておこう。`}</p>
    <p>{`迷っている人へ。Wordは年賀状や名刺、POP作りに、Excelは家計簿やリスト管理、パワーポイントは旅行のしおりやブログの画像作成などにも使え、意外と用途は幅広い。KingSoftなど格安のオフィス互換ソフトと異なり、ネット上に情報が多くて使いやすい。`}</p>
    <p>{`更に、`}<strong parentName="p">{`後から購入するとパソコンとセットで購入した場合と比べて倍近い価格になる`}</strong>{`ため少しでも欲しいと思ったならば付けておくと良いだろう。`}</p>
    <p>{`Officeを使用する別の方法として、`}<strong parentName="p"><em parentName="strong">{`Microsoft365`}</em>{`という月1000円程度でオフィスソフトが台数制限無で使えるサブスクリプションプラン`}</strong>{`もあるが、毎月課金型は心理的に負担があるため個人的にはそこまでおすすめしない。`}</p>
    <Card {...props} title="オフィスソフトは必要か？" description="搭載するか否か、互換ソフトやOffice365等で悩んでいる人へ情報をまとめています。" image="coffice" path="/office/" mdxType="Card" />
    <h3 {...{
      "id": "モニタディスプレイ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A2%E3%83%8B%E3%82%BF%E3%83%87%E3%82%A3%E3%82%B9%E3%83%97%E3%83%AC%E3%82%A4",
        "aria-label": "モニタディスプレイ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`モニタ(ディスプレイ)`}</h3>
    <Image {...props} name="monitor_s" alt="モニタの選び方" mdxType="Image" />
    <p>{`モニタでは`}<strong parentName="p"><em parentName="strong">{`ディスプレイサイズ`}</em>{`、`}<em parentName="strong">{`表面の光沢`}</em>{`、`}<em parentName="strong">{`解像度`}</em>{`の３つを最低限考える`}</strong>{`必要がある。`}</p>
    <p>{`まずディスプレイサイズ。ノートパソコンでは大きいほど作業はしやすいため携帯性とバランスを取る必要がある。デスクトップならば23.8インチ程度が標準的であり、コスパも高い。`}</p>
    <p>{`次に光沢の有無。光沢があり反射するタイプを`}<em parentName="p">{`グレアモニタ`}</em>{`、光沢が無く反射しないタイプを`}<em parentName="p">{`ノングレアモニタ`}</em>{`と呼ぶ。`}<strong parentName="p">{`作業のしやすさやインターネットの閲覧を考えるとノングレアタイプがおすすめ`}</strong>{`である。反射で目がチカチカする心配がない。`}</p>
    <p>{`映像視聴を主にして、より綺麗な映像を楽しみたいという意思があるならばグレアもありだが、反射が気になる事も多いため`}<strong parentName="p">{`ノングレアの方が総合的におすすめ`}</strong>{`できる。事実８割方モニタはノングレアタイプである。`}</p>
    <Image {...props} name="fullHD_s" alt="FullHD液晶モニター" mdxType="Image" />
    <p>{`最後に解像度。解像度は画面を構成するドットと呼ばれる正方形の数であり、4K(3840x2160)、FHD(1920x1080)、WXGA(1366x766)などがある。FHD(フルHD)ならば横に1920、縦に1080のドットで画面が構成される。`}</p>
    <p><strong parentName="p">{`解像度が高いほど高精細で映像は美しく、文字はクッキリと表示される`}</strong>{`。WXGAはモバイルノートであれ粗さが目立つため、最低でもFHDの解像度を確保することが重要。`}</p>
    <p>{`デスクトップ用のモニタを別途購入しようとしている場合は下記に詳しく記述しているので参考にして欲しい。`}</p>
    <Card {...props} title="液晶モニターの選び方" description="液晶モニターの選び方を作業・映像・ゲーム等用途別に詳しく解説。" image="cmonitor" path="/monitor/" mdxType="Card" />
    <h3 {...{
      "id": "ウイルス対策ソフトウイルスバスター",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E5%AF%BE%E7%AD%96%E3%82%BD%E3%83%95%E3%83%88%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E3%83%90%E3%82%B9%E3%82%BF%E3%83%BC",
        "aria-label": "ウイルス対策ソフトウイルスバスター permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ウイルス対策ソフト(ウイルスバスター)`}</h3>
    <p>{`セキュリティ対策を行うためのソフト、日本ではトレンドマイクロ社の`}<em parentName="p">{`ウイルスバスター`}</em>{`が非常に有名で代名詞としても使われる。`}</p>
    <p>{`偽サイトへの誘導してIDとパスワードを入力させようとする`}<em parentName="p">{`フィッシング詐欺`}</em>{`や怪しげなメールのクリック、いかがわしいフリーソフトの実行などからユーザを守ってくれる。`}</p>
    <p><strong parentName="p">{`最初の３カ月程度は有料のウイルス対策ソフトが無料で使える`}</strong>{`事も多く、その間に研究しても良いし、Windows標準の`}<em parentName="p">{`Windows Defender`}</em>{`で十分なことも多いので、`}<strong parentName="p">{`パソコンの購入と同時に検討する必要は全くない`}</strong>{`。`}</p>
    <p>{`ウイルス対策の詳細は下記を参照。`}</p>
    <Card {...props} title="ウイルス対策ソフト（ウイルスバスター）は必要か？" description="ウイルス対策ソフトは必要か？Windows Defenderで代替可能か？有料と無料の機能的な差は何か？等、必要性を徹底比較検討。" image="cvirus" path="/virus/" mdxType="Card" />
    <h3 {...{
      "id": "ワイヤレスマウス、キーボード",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AF%E3%82%A4%E3%83%A4%E3%83%AC%E3%82%B9%E3%83%9E%E3%82%A6%E3%82%B9%E3%80%81%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89",
        "aria-label": "ワイヤレスマウス、キーボード permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ワイヤレスマウス、キーボード`}</h3>
    <Image {...props} name="keyboardmouse_s" alt="ワイヤレスキーボードとワイヤレスマウス" mdxType="Image" />
    <p><strong parentName="p">{`デスクトップPCなら是非欲しい`}</strong>{`。配線が不要となりスッキリする。電池も１年程度は持つのであまり気にならない。`}</p>
    <p>{`ノートパソコンの場合もノートパッドがあるため必須ではないが、マウスの方が使いやすいためおすすめである。`}</p>
    <p>{`ただし、無線だと環境によって稀に遅延が発生することもあり得るので、気になる場合や動作の安定性を最重視したい場合には有線に切り替えると良い。`}</p>
    <h3 {...{
      "id": "DVD、BDドライブ光学ドライブ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#DVD%E3%80%81BD%E3%83%89%E3%83%A9%E3%82%A4%E3%83%96%E5%85%89%E5%AD%A6%E3%83%89%E3%83%A9%E3%82%A4%E3%83%96",
        "aria-label": "DVD、BDドライブ光学ドライブ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DVD、BDドライブ(光学ドライブ)`}</h3>
    <Image {...props} name="bddvddrive_s" alt="DVDドライブとBDドライブ" mdxType="Image" />
    <p>{`CD、DVD、BD(ブルーレイディスク)を再生するために必要な機器。光を照射してデータを読みとる性質上光学ドライブといわれる。`}</p>
    <p>{`USBやインターネット上にデータを保存することも多くなり、DVDを使う機会が減ったため、薄型ノートPCには基本付属しない。`}</p>
    <p>{`しかし、`}<strong parentName="p">{`音楽CDを取り込む、本に付属のDVDを読み込む、宴会のためのムービーを作成し保存するなど利用ケースはまだまだ多いといえるので一つは欲しい`}</strong>{`が正直なところ。`}</p>
    <p><strong parentName="p">{`外付けのDVD,BDドライブも安く買えるので代用するのも賢い選択肢`}</strong>{`である。名称により読み書きできる種類が異なるため、下記の表は頭に入れておきたい。`}</p>
    <BasicTable className="nb center" mdxType="BasicTable">
      <thead>
  <tr>
    <th></th>
    <th>DVD読み込み</th>
    <th>DVD書き込み</th>
    <th>BD読み込み</th>
    <th>BD書き込み</th>
  </tr>
      </thead>
      <tbody>
  <tr>
    <td className="left">DVDコンボドライブ</td>
    <td>◯</td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
  <tr>
    <td className="left">DVD(スーパー)マルチドライブ</td>
    <td>◯</td>
    <td>◯</td>
    <td></td>
    <td></td>
  </tr>
  <tr>
    <td className="left">BDコンボドライブ</td>
    <td>◯</td>
    <td>◯</td>
    <td>◯</td>
    <td></td>
  </tr>
  <tr>
    <td className="left">BDディスクドライブ</td>
    <td>◯</td>
    <td>◯</td>
    <td>◯</td>
    <td>◯</td>
  </tr>
      </tbody>
    </BasicTable>
    <p>{`より詳しくは下記を参照。`}</p>
    <Card {...props} title="DVD,BDドライブは必要か？" description="パソコンにDVD/BDドライブは本当に必要なのかを検討。スーパーマルチドライブなど紛らわしい名称の違いも解説" image="cdrive" path="/drive/" mdxType="Card" />
    <h3 {...{
      "id": "無線LANWi-Fi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%84%A1%E7%B7%9ALANWi-Fi",
        "aria-label": "無線LANWi Fi permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`無線LAN(Wi-Fi)`}</h3>
    <Image {...props} name="wirelesslan_s" alt="無線LANルーター" mdxType="Image" />
    <p>{`インターネットにケーブルなしで繋ぐことができる無線LANであるが、`}<strong parentName="p">{`高速で安定した通信を目指すなら`}<em parentName="strong">{`Wi-Fi 6(IEEE802.11ax)`}</em>{`という無線規格に対応したものを選ぼう。`}</strong></p>
    <p>{`最近の不安定さの要因の１つはテレビやスマホ、その他家電など複数の機器がWi-Fiに繋がるようになったことであるが、`}<strong parentName="p">{`Wi-Fi 6は複数の端末から接続しても速度が落ちにくい`}</strong>{`。`}</p>
    <p>{`私はマンションに住んでおり、周りにテレワークをしている人が多いものの、Wi-Fi6対応ルータに切り替えたことでネットワークを有線並みに安定させることができた。`}</p>
    <p>{`Wi-Fi6は障害物に強く到達距離が長い`}<em parentName="p">{`2.4GHz帯`}</em>{`と電子レンジなど他の電波の干渉を受けない`}<em parentName="p">{`5.0GHz帯`}</em>{`という２つの周波数帯をサポートするが、`}<strong parentName="p">{`6GHz帯をサポートする`}<em parentName="strong">{`Wi-Fi 6E`}</em>{`というWi-Fi 6の拡張規格も登場`}</strong>{`している。`}</p>
    <p>{`Wi-Fi6E対応ルータは高価ではあるが、新たな周波数帯を使えることにより、更に干渉を受けにくい安定的な無線LAN環境が手に入る。マンションに住んでおり電波環境が悪いような場合は要チェックである。`}</p>
    <h3 {...{
      "id": "端子HDMI、USB-Type-A、Type-C等",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%AB%AF%E5%AD%90HDMI%E3%80%81USB-Type-A%E3%80%81Type-C%E7%AD%89",
        "aria-label": "端子HDMI、USB Type A、Type C等 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`端子(HDMI、USB Type-A、Type-C等)`}</h3>
    <Image {...props} name="noteconnect" alt="ノートパソコンの端子類" mdxType="Image" />
    <p>{`他の機器と接続するための端子。デスクトップパソコンやスタンダードノートでは十分な端子が揃っているためそれほど気を払う必要は無い。しかし`}<strong parentName="p">{`モバイルノートでは薄型化で多くの端子が削られている可能性が高いので注意が必要`}</strong>{`である。`}</p>
    <p>{`ただ、万が一必要な端子が無くとも`}<strong parentName="p">{`変換器やハブといった機器を購入して取り付ければ何とかなる場合が大半`}</strong>{`のため、そこまで神経質になる必要はない。`}</p>
    <AccentBox title="端子の説明と注意点" mdxType="AccentBox">
  <li><span className="bold">HDMI端子</span>・・・モニタへの接続で最も使われる</li>
  <li><span className="bold">DVI端子</span>・・古いモニタと接続するための端子、既存のモニタを使いまわしたい場合に必要な可能性もある</li>
  <li><span className="bold">USB Type-A</span>・・・マウス、キーボード、無線LAN子機、USB扇風機など幅広く使われている。ノートでも３つぐらいあると困らないが外付けで簡単に増やせる</li>
  <li><span className="bold">USB Type-C</span>・・最新のモバイルノートで搭載される事が多い、様々な用途に使える端子</li>
  <li><span className="bold">LAN端子</span>・・インターネット接続で必要な端子</li>
    </AccentBox>
    <p>{`このうちType-Cはくせもので、`}<strong parentName="p">{`電源供給(Power Delivery)、データ伝送、映像出力の３つの機能があるが、全て備わっているかは分からない`}</strong>{`という特徴がある。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Thunderbolt`}</em>{`と書いてあると全機能入りのType C`}</strong>{`で映像出力や急速充電用等の電源供給ができることが保証される。詳しく知りたい場合は下記を参照。`}</p>
    <Card {...props} title="USB Type-Cとは?USB3.1、PD、Altモードなど周辺技術を解説" description="同じType-Cでも、どの機能に対応しているかをきちんと見極められるようになります。" image="cusbtypec" path="/usb_type-c/" mdxType="Card" />
    <h3 {...{
      "id": "保証は１年以上だと損をする",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%BF%9D%E8%A8%BC%E3%81%AF%EF%BC%91%E5%B9%B4%E4%BB%A5%E4%B8%8A%E3%81%A0%E3%81%A8%E6%90%8D%E3%82%92%E3%81%99%E3%82%8B",
        "aria-label": "保証は１年以上だと損をする permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`保証は１年以上だと損をする`}</h3>
    <p>{`パソコン以外にも言える事だが電化製品には1年のメーカー保証を基本として、3年、5年保証を有料で選べることが多いが`}<strong parentName="p">{`1年保証以外は基本的に不要`}</strong>{`である。`}</p>
    <p>{`バスタブカーブという言葉を知っているだろうか？故障率は最初の1年間は高いが、その後2～5年後は低くなり、更に期間が経つとまた高くなる。`}</p>
    <p>{`故障率が時間とともに高い、低い、高い状態となり、故障率を縦軸、時間を横軸としてグラフを描くとバスタブのようになることからこのように呼ばれている。`}</p>
    <p>{`要するに、`}<strong parentName="p">{`2～5年目は故障率が統計的に高くないため有料で保証に入ってもそれほどメリットはない`}</strong>{`という事である。実際ヨドバシカメラで働いていた時は5年保証は利益率が高いため頑張って加入させるようにと言われていた。`}</p>
    <p>{`しかし万が一という事もあるのでここは個人の判断にお任せしたいと思う。また、３年保証が自動で付いてくるキャンペーンなども多いのでその場合は利用したい。`}</p>
    <h2 {...{
      "id": "目的別パソコンの選び方",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%9B%AE%E7%9A%84%E5%88%A5%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E9%81%B8%E3%81%B3%E6%96%B9",
        "aria-label": "目的別パソコンの選び方 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`目的別パソコンの選び方`}</h2>
    <p>{`パソコンの種類・パーツについて理解できたところで、目的や用途別、使用者のレベル別にどのようなパソコンが適しているのか、注意点を踏まえて簡単に解説していく。より詳細は各リンク先ページを参照として欲しい。`}</p>
    <h3 {...{
      "id": "初心者は失敗しないことをまず避ける",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%88%9D%E5%BF%83%E8%80%85%E3%81%AF%E5%A4%B1%E6%95%97%E3%81%97%E3%81%AA%E3%81%84%E3%81%93%E3%81%A8%E3%82%92%E3%81%BE%E3%81%9A%E9%81%BF%E3%81%91%E3%82%8B",
        "aria-label": "初心者は失敗しないことをまず避ける permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`初心者は失敗しないことをまず避ける`}</h3>
    <p>{`パソコン購入での失敗には、後から取り返せる失敗と、どうにもならない失敗の２つがある。`}</p>
    <p>{`例えば容量が足りない場合やDVDが再生できないという失敗は、外付け機器を購入すればよく後から取り返せるケースも多い。一方で遅い動作や、持ち運びに不便という失敗は買い替えを余儀なくされる。`}</p>
    <p>{`パソコンは選び方のポイントが多く、完璧を求めるといつまでも決められないため、初心者はこのどうにもならない失敗の回避に神経を注ぐべきである。特にノートパソコンは拡張性が低く失敗の影響が大きいため細心の注意が必要である。`}</p>
    <Card {...props} title="【データで判明】初心者におすすめのノートパソコン" description="パソコンに苦手意識を持つ人がパソコン購入時に失敗しやすいポイントを中心に解説します。" image="cbeginner" path="/beginner/" mdxType="Card" />
    <h3 {...{
      "id": "大学生は軽量モバイルノート・エンタメ性も重視",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%A4%A7%E5%AD%A6%E7%94%9F%E3%81%AF%E8%BB%BD%E9%87%8F%E3%83%A2%E3%83%90%E3%82%A4%E3%83%AB%E3%83%8E%E3%83%BC%E3%83%88%E3%83%BB%E3%82%A8%E3%83%B3%E3%82%BF%E3%83%A1%E6%80%A7%E3%82%82%E9%87%8D%E8%A6%96",
        "aria-label": "大学生は軽量モバイルノート・エンタメ性も重視 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`大学生は軽量モバイルノート・エンタメ性も重視`}</h3>
    <p>{`キャンパス内の移動が多いのでモバイルノートがおすすめである。13.3から14インチ程度が適当であろう。`}</p>
    <p>{`またサークル活動などをやっていると写真や動画を取ることも多いので、ストレージ容量を512GBなど多めに取っておくと４年間困る事はないだろう。`}</p>
    <p>{`また、360度回転できるコンバーチブルはタブレットとしても使え、家でYoutubeなどを見るときにちょうど良かったりするため検討の余地はある。`}</p>
    <Card {...props} title="大学生向けおすすめノートパソコン" description="軽量で高性能、就職活動を含め４年間安心して使えるノートパソコンを現役エンジニアが徹底解説。" image="cdaigakusei" path="/daigakusei/" mdxType="Card" />
    <h3 {...{
      "id": "ビジネスパーソンはCPU性能を重視",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%83%91%E3%83%BC%E3%82%BD%E3%83%B3%E3%81%AFCPU%E6%80%A7%E8%83%BD%E3%82%92%E9%87%8D%E8%A6%96",
        "aria-label": "ビジネスパーソンはCPU性能を重視 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ビジネスパーソンはCPU性能を重視`}</h3>
    <p>{`大学生と同様13.3から14インチ程度のサイズが一般的で、家では外付けのモニタで画面表示領域を拡大すると効率が向上する。`}</p>
    <p>{`軽量性も重要であるが、もっとも重要なのがCPU性能である。特にセキュリティ対策から重めのウイルス対策ソフトを入れさせられ、リモート会議もCPUリソースの消費が大きい。`}</p>
    <p>{`このため`}<a href="/cpu/">{`CPU性能比較表`}</a>{`などを参考にして慎重に選ぼう。基本的にはIntelの12世代、Core i5以上のものを積んでおけば問題はないだろう。`}</p>
    <Card {...props} title="一流のビジネスパーソンが選ぶおすすめのノートパソコン５選" description="仕事で圧倒的なパフォーマンスを出したい人へ、最強のビジネスノートパソコンを選び方を含めて紹介します。" image="cbusiness" path="/business/" mdxType="Card" />
    <h3 {...{
      "id": "ゲーミングは期待FPSを想定してGPUを決定",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B2%E3%83%BC%E3%83%9F%E3%83%B3%E3%82%B0%E3%81%AF%E6%9C%9F%E5%BE%85FPS%E3%82%92%E6%83%B3%E5%AE%9A%E3%81%97%E3%81%A6GPU%E3%82%92%E6%B1%BA%E5%AE%9A",
        "aria-label": "ゲーミングは期待FPSを想定してGPUを決定 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ゲーミングは期待FPSを想定してGPUを決定`}</h3>
    <p>{`ゲーミングPCでグラフィック描画性能を決める一番の要素がGPUであり、遊びたい画面の解像度と滑らかさの基準であるFPSの目標値によって選ぶGPUが変わってくる。`}</p>
    <p>{`FullHDの解像度で60FPSというのが一般的で、これであるならば２万円程度のグラフィックボードで十分に遊ぶことができる。`}</p>
    <p>{`しかし、4Kやより高いFPS、レイトレーシング対応を目指すと高価なGPUが必要となり、モニターも対応したものにしなければならない。GPUの性能比較表と照らし合わせつつ検討したい。`}</p>
    <Card {...props} title="【初心者向け】ゲーミングPCの選び方" description="FullHDで60FPSをボトムとして144FPS、4K、レイトレーシングを実現する快適なパソコンスペックを追求" image="cgamingpc" path="/gamingpc/" mdxType="Card" />
    <h3 {...{
      "id": "本格的な動画編集はマルチスレッドCPU大量メモリ搭載",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%9C%AC%E6%A0%BC%E7%9A%84%E3%81%AA%E5%8B%95%E7%94%BB%E7%B7%A8%E9%9B%86%E3%81%AF%E3%83%9E%E3%83%AB%E3%83%81%E3%82%B9%E3%83%AC%E3%83%83%E3%83%89CPU%E5%A4%A7%E9%87%8F%E3%83%A1%E3%83%A2%E3%83%AA%E6%90%AD%E8%BC%89",
        "aria-label": "本格的な動画編集はマルチスレッドCPU大量メモリ搭載 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`本格的な動画編集はマルチスレッドCPU+大量メモリ搭載`}</h3>
    <p>{`撮影した動画にテロップを付ける程度のことなら普通のパソコンで問題ないが、3Dモデリングを行い光の反射をシュミレートするなどといった高度な演算が必要となる画像や映像を生成するには高度なCPUや大容量のメモリが必要となる。`}</p>
    <p>{`プロ並みのクリエイティブな作業をしたいというケースは下記のページが参考になるだろう。`}</p>
    <Card {...props} title="本格的な動画編集を行いたい人へ向けたパソコンの選び方" description="AfterEffectsや3DCGソフトで本格的な動画編集を始めたい人へ、最適なスペックを基礎から教えます。" image="ceditvideo" path="/editvideo/" mdxType="Card" />
    <h2 {...{
      "id": "パソコンメーカーの選び方",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%83%A1%E3%83%BC%E3%82%AB%E3%83%BC%E3%81%AE%E9%81%B8%E3%81%B3%E6%96%B9",
        "aria-label": "パソコンメーカーの選び方 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコンメーカーの選び方`}</h2>
    <Image {...props} name="pcmaker" alt="パソコンメーカー" mdxType="Image" />
    <p>{`数多くあるパソコンメーカーの中で一社を選ぶことは難しいかもしれない。そのような時には大枠を掴んでから詳細を絞るべきである。そこで特徴ごとに`}<strong parentName="p">{`国内大手、海外大手、国内BTO、海外OS提供メーカー(マイクロソフト)の４つのタイプ`}</strong>{`に分け、それぞれに適したユーザー及び大まかな特徴を説明する。`}</p>
    <b className="mobile-margin">国内大手メーカー(富士通、NEC、Dynabook等)</b><br />
    <p><strong parentName="p">{`初期ソフトやWebサイトでのトラブルシューティングが充実し、サポート体制も整っている`}</strong>{`ため特にパソコン初心者にとっておすすめできる他、小型化をお家芸とした`}<strong parentName="p">{`軽量・堅牢なモバイルノートには定評`}</strong>{`がある。`}</p>
    <b className="mobile-margin">海外大手メーカー(Dell、Lenovo、HP)</b><br />
    <p><strong parentName="p">{`高いシェアを武器とした価格競争力が最大の強み`}</strong>{`であり、同スペックの国内大手の少なくとも２割は割安に購入することができる。ある程度パソコンに慣れ人向け。`}</p>
    <b className="mobile-margin">国内BTO(マウスコンピュータ、パソコン工房、フロンティア等)</b><br />
    <p>{`コストパフォーマンスも然ることながらメーカー製と比べて細かいスペックの指定が可能で、`}<strong parentName="p">{`より自分にあったパソコンを注文できることが強み`}</strong>{`である。ちなみにBTOはBuild to Order、すなわち受注生産のことである。`}</p>
    <b className="mobile-margin">OS提供メーカー(マイクロソフト)</b><br />
    <p><strong parentName="p">{`デザイン性が高く、Microsoft Officeが標準で付属する`}</strong>{`というOS提供社ならではの独自なポジションを築いている。カフェなどに持ち歩いてビジネスや勉強をしたい人におすすめできる。`}</p>
    <p>{`以上、大まかに解説をおこなったが、より詳しく調べたい場合は下記のパソコンメーカーランキングを参照してほしい。`}</p>
    <Card {...props} title="パソコンメーカーおすすめランキング" description="パソコンメーカーのおすすめを種類や用途・コスパ・デザインなど様々な観点からランキング付きで紹介します。" image="cpcmaker" path="/pcmaker/" mdxType="Card" />
    <h2 {...{
      "id": "パソコンを買うならどこか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%82%92%E8%B2%B7%E3%81%86%E3%81%AA%E3%82%89%E3%81%A9%E3%81%93%E3%81%8B%EF%BC%9F",
        "aria-label": "パソコンを買うならどこか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パソコンを買うならどこか？`}</h2>
    <p><strong parentName="p"><em parentName="strong">{`品揃えとカスタマイズ性に優れ、保証が約束されるメーカー直販店で購入することが一番良い`}</em></strong>{`と思われるが、メーカーによりけりといった側面もあり、NEC、富士通、Dynabookなどは量販店が強い側面もある。`}</p>
    <p>{`下記の記事を参考に欲しい。私は元ヨドバシで販売員をしていたこともあるのでいくらか参考になると思う。`}</p>
    <Card {...props} title="パソコンはどこで買うべきか？" description="パソコンを買うならどこがおすすめかをメーカーごとに、販売価格や品揃え、保証などを踏まえて解説。" image="cwhere" path="/where/" mdxType="Card" />
    <p>{`また、中古パソコンにおいては品質や価格、保証面で優良な中古パソコンショップをおすすめするが、これについては下記を参考にしてほしい`}</p>
    <Card {...props} title="中古パソコンはどこで買うべきか？" description="中古パソコンを買うならどこがおすすめかを品質と価格を中心に、選び方も含めて解説します。" image="cusedpc" path="/used-pc/" mdxType="Card" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      